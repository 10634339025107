import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Text,
  VStack,
  Divider,
  Box,
  Icon,
  ModalHeader
} from '@chakra-ui/react'
import { FC } from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'

export interface IChangePasswordModal {
  open: () => void
}

type Props = {
  language: string
  isOpen: boolean
  onClose: () => void
  onApply: (reverse: boolean) => void
}

const DictionaryModeModal: FC<Props> = ({
  language,
  onClose,
  onApply,
  isOpen
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay
        bg='blackAlpha.600'
        backdropFilter='auto'
        backdropBlur='2px'
      />
      <ModalContent w='96' minH='60' rounded='8px'>
        <ModalHeader>
          <Text textStyle={'body'} fontWeight={700}>
            Выберите режим
          </Text>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody p={6} h={'full'}>
          <VStack spacing={4} w='full' align={'flex-start'}>
            <Divider />
            <Box
              as='button'
              w='full'
              onClick={() => onApply(false)}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Text textStyle={'h4'} fontWeight={500}>
                {language} - Russian
              </Text>
              <Icon as={ChevronRightIcon} />
            </Box>
            <Divider />
            <Box
              as='button'
              w='full'
              onClick={() => onApply(true)}
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Text textStyle={'h4'} fontWeight={500}>
                Russian - {language}
              </Text>
              <Icon as={ChevronRightIcon} />
            </Box>
            <Divider />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DictionaryModeModal
