import { onSnapshot, query, collection } from 'firebase/firestore'
import { db } from 'src/controllers/db'
import { ILanguage } from 'shared/types'
import { receiveLanguages } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'

export const dbFetchLanguages = async () => {
  try {
    const q = query(collection(db, 'languages'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, ILanguage> = {}
        sn.forEach(doc => {
          const p = doc.data() as ILanguage
          res[doc.id] = p
        })
        store.dispatch(receiveLanguages(res))
      },
      err => {
        console.log(`dbFetchLanguages error: ${err.message}`)
      }
    )
    addListener('languages', unsubscribe)
  } catch (e) {
    console.error('dbFetchLanguages error', e)
  }
}
