import { onAuthStateChanged, User } from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'
import startsWith from 'lodash/startsWith'
import { auth } from 'controllers/db'
import { dbFetchCourses } from 'controllers/courses'
import { dbFetchWebinars } from 'controllers/webinars'
import { dbFetchLibrary } from 'controllers/library'
import store from 'model/store'
import { receiveAuthData } from 'model/actions'
import { dbFetchUserPayments } from 'controllers/userPayments'
import { dbFetchUser } from 'controllers/user'
import { dbFetchPartitions } from 'controllers/partitions'
import { dbFetchLevels } from 'controllers/levels'
import { dbFetchTeachers } from 'controllers/teachers'
import { dbFetchSeminars } from 'controllers/seminars'
import { dbFetchChatChannels } from 'controllers/chat'
import { dbFetchFrees } from 'controllers/frees'
import { dbFetchCoursesPacks } from 'controllers/coursesPacks'
import { dbFetchCardTags } from 'controllers/cardTags'
import { dbFetchLanguages } from 'controllers/languages'

const fetchUser = async (authData: User, navigate: NavigateFunction) => {
  // console.log('fetchUser', authData)
  await Promise.all([
    dbFetchUserPayments(authData.uid),
    dbFetchUser(authData.uid),
    dbFetchChatChannels(authData.uid)
  ])
}

const onAuth = async (authData: User | null, navigate: NavigateFunction) => {
  // console.log('onAuth', authData)
  const pathname = window.location.pathname
  if (authData) {
    // console.log('authData', authData)

    store.dispatch(
      receiveAuthData({ uid: authData.uid, email: authData.email })
    )
    if (pathname === '/login') {
      navigate('/profile')
    }
    fetchUser(authData, navigate)
  } else {
    if (startsWith(pathname, '/profile')) {
      navigate('/login')
    }
  }
}

const fetchPublicData = async () => {
  await Promise.all([
    dbFetchCourses(),
    dbFetchCoursesPacks(),
    dbFetchFrees(),
    dbFetchWebinars(),
    dbFetchLibrary(),
    dbFetchPartitions(),
    dbFetchLevels(),
    dbFetchTeachers(),
    dbFetchSeminars(),
    dbFetchCardTags(),
    dbFetchLanguages()
  ])
}

export const appInitialized = (navigate: NavigateFunction): void => {
  try {
    fetchPublicData()
    onAuthStateChanged(auth, authData => onAuth(authData, navigate))
  } catch (e) {
    console.log('app initialization error', e)
  }
}
