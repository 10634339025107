import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import get from 'lodash/get'
import map from 'lodash/map'
import compact from 'lodash/compact'
import { useParams } from 'react-router-dom'
import { useSelector } from 'model/hooks'
import { ILessonType, SectionType } from 'shared/types'
import { dbGetWordCards, dbSetUserCardStatus } from 'controllers/cards'
import { Button, HStack, VStack } from '@chakra-ui/react'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import ContentContainer from 'shared/components/ContentContainer'
import BackButton from 'shared/components/BackButton'
import { MAX_WIDTH } from 'shared/constants/main'
import TrainingCard from 'pages/user/dictionaryTraining/TrainingCard'
import { useSearchParams } from 'react-router-dom'

const DictionaryTraining: FC = () => {
  const { lessons, entity, entityType } = useContext(LessonsContext)
  const [searchParams] = useSearchParams()
  const wordCards = useSelector(state => state.wordCards)
  const cardTags = useSelector(state => state.cardTags)
  const [curCardIndex, setCurrentCardIndex] = useState(0)
  const user = useSelector(state => state.user)
  const [status, setStatus] = useState<boolean | null>(null)
  const timerRef = useRef<number>(0)
  const { weekId, dayId, lessonId } = useParams() as {
    weekId: string
    dayId: string
    lessonId: string
  }

  useEffect(() => {
    setStatus(null)
  }, [curCardIndex])

  const reverse = useMemo(() => {
    return searchParams.get('r') === '1'
  }, [searchParams])

  const lesson = useMemo(() => {
    return get(lessons, lessonId)
  }, [lessons, lessonId])

  useEffect(() => {
    if (lesson?.sections) {
      if (lesson.type === ILessonType.DICTIONARY) {
        const rawCardsIds = map(lesson.sections, s => {
          if (s.type === SectionType.WORD_CARD) {
            return s.cardId || null
          } else {
            return null
          }
        })
        const ids = compact(rawCardsIds)
        dbGetWordCards(ids)
      }
    }
  }, [lesson?.sections, lesson?.type])

  const cards = useMemo(() => {
    if (lesson?.sections && wordCards) {
      const res = map(lesson.sectionsOrder, sId => {
        const s = get(lesson.sections, sId)
        if (s && s.type === SectionType.WORD_CARD) {
          return get(wordCards, s.cardId)
        } else {
          return null
        }
      })
      return compact(res)
    } else {
      return []
    }
  }, [wordCards, lesson?.sections, lesson?.sectionsOrder])

  const toNext = () => {
    if (curCardIndex === cards.length - 1) {
      setCurrentCardIndex(0)
    } else {
      setCurrentCardIndex(curCardIndex + 1)
    }
  }

  const toPrev = () => {
    if (curCardIndex === 0 && cards.length > 1) {
      setCurrentCardIndex(cards.length - 1)
    } else {
      setCurrentCardIndex(curCardIndex - 1)
    }
  }

  const renderCard = () => {
    const c = get(cards, curCardIndex)
    if (c && cardTags) {
      return (
        <VStack pt={{ base: '4vh', lg: '12vh' }} w='full' align='center'>
          <TrainingCard
            key={c.id}
            c={c}
            cardTags={cardTags}
            num={curCardIndex + 1}
            amount={cards.length}
            toNext={toNext}
            toPrev={toPrev}
            status={status}
            reverse={reverse}
          />
        </VStack>
      )
    }
  }

  const onTimerEnd = () => {
    timerRef.current = 0
    toNext()
  }

  const addTimer = () => {
    timerRef.current = window.setTimeout(onTimerEnd, 1000)
  }

  const onKnow = () => {
    const c = get(cards, curCardIndex)
    if (c) {
      dbSetUserCardStatus(lessonId, c.id, true)
      setStatus(true)
      addTimer()
    }
  }

  const onDoNotKnow = () => {
    const c = get(cards, curCardIndex)
    if (c) {
      dbSetUserCardStatus(lessonId, c.id, false)
      setStatus(false)
      addTimer()
    }
  }

  const renderButtons = () => {
    const c = get(cards, curCardIndex)
    return (
      <HStack pt={10} w='full' justify={'center'} key={c?.id || 'buttons'}>
        <Button
          variant={'outline'}
          bg='white'
          _hover={{ bg: 'wood.50' }}
          borderColor={'black.950'}
          rounded='full'
          color='black.950'
          w={{ base: 'full', lg: '150px' }}
          onClick={onKnow}
          isDisabled={status !== null}
        >
          Знаю
        </Button>
        <Button
          variant={'outline'}
          bg='white'
          _hover={{ bg: 'wood.50' }}
          borderColor={'black.950'}
          rounded='full'
          color='black.950'
          w={{ base: 'full', lg: '150px' }}
          onClick={onDoNotKnow}
          isDisabled={status !== null}
        >
          Не знаю
        </Button>
      </HStack>
    )
  }

  if (lesson && entity && weekId && dayId && user) {
    return (
      <PageContentWrapper>
        <ContentContainer
          bg='wood.100'
          backBg='white'
          borderBottomRadius={{ base: 'sm', lg: 'lg' }}
          containerProps={{ role: 'group' }}
          minH='100vh'
        >
          <HStack
            justify={'start'}
            w='full'
            maxW={MAX_WIDTH}
            pt={{ lg: 16, base: 16 }}
          >
            <BackButton
              backUrl={`/profile/${entityType}/${entity.id}/${weekId}/${dayId}/${lesson.id}`}
            />
          </HStack>

          {renderCard()}
          {renderButtons()}
        </ContentContainer>
      </PageContentWrapper>
    )
  } else {
    return null
  }
}

export default DictionaryTraining
